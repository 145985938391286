<div [ngClass]="'p-grid flex flex-row cursor-pointer'">
  @if(loading){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  } @else { @for (company of companies; track company.id) {
  <div
    (click)="goTo(company.id)"
    [ngClass]="'mr-4'"
    class="p-col-3 p-md-4"
    [style]="{ width: '360px' }"
  >
    <ng-container>
      <p-card header="{{ company.company_name }}" class="p-col-3 cardDashboard">
        <ng-template pTemplate="header">
          <div style="text-align: end">
            <p-chip class="custom-chip-primary" label="ESG"> </p-chip>
          </div>
          <img src="assets/logos/mercadona_integration.png" alt="Imagen" />
        </ng-template>
        <ng-template pTemplate="content">
          <div [ngClass]="">
            <button pButton label="Acceder" class="p-button"></button>
          </div> </ng-template
      ></p-card>
    </ng-container>
  </div>
  } }
</div>
