import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UsersPage } from './users.page';

const routes: Routes = [
  {
    path: '',
    component: UsersPage
  },
  {
    path: 'create-users',
    data: { breadcrumb: 'Create Users' },
    loadChildren: () => import('./create-users/create-users.module').then(m => m.CreateUsersPageModule)
  },
  {
    path: 'edit-users/:id',
    data: { breadcrumb: 'Edit Users' },
    loadChildren: () => import('./edit-users/edit-users.module').then(m => m.EditUsersPageModule)
  },
  {
    path: 'assign-users/:id',
    data: { breadcrumb: 'Assign User' },
    loadChildren: () => import('./assign-users/assign-users.module').then(m => m.AssignUsersPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersPageRoutingModule { }
