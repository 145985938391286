import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { switchMap, concatMap } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static apiUrl = environment.usersApiURL;
  private userData: any;

  constructor(private http: HttpClient) {}

  setUserData(user: any): void {
    this.userData = user;
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  getUserData(): any {
    return this.userData;
  }

  getActualUser() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
    });

    return this.http
      .get<any>(`${UserService.apiUrl}/api/user`, { headers: headers })
      .pipe(
        concatMap((user) => {
          this.userData = user;
          return of(user);
        })
      );
  }

  getPermissions() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
    });
    return this.getActualUser().pipe(
      switchMap(() => {
        return this.http.get<any>(
          `${UserService.apiUrl}/api/users/permissions/${
            this.getUserData().id
          }`,
          { headers: headers }
        );
      })
    );
  }
}
