<div class="p-grid">
  <div class="p-col-12">
    <h2>Listado de Empresas</h2>
  </div>

  <p-confirmDialog></p-confirmDialog>
  <p-messages [value]="msgs"></p-messages>

  @if(loading){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  } @else {

  <div class="p-col-12 mb-4 custom-align-right">
    <button
      pButton
      type="button"
      label="Crear Nueva Empresa"
      icon="pi pi-plus"
      (click)="redirectToCreateCompany()"
    ></button>
  </div>

  <div class="p-col-12" *ngIf="companies && companies.length > 0">
    <p-table [value]="companies" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th>Razón social</th>
          <th>Código Postal</th>
          <th>Dirección Fiscal</th>
          <th>Email</th>
          <th>Options</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr>
          <td>{{ company.company_name }}</td>
          <td>{{ company.fiscal_postal_code }}</td>
          <td>{{ company.fiscal_address }}</td>
          <td>{{ company.email }}</td>
          <td>
            <button
              pButton
              type="button"
              class="mr-2"
              icon="pi pi-pencil"
              (click)="onEditClick(company)"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="onDeleteClick(company.id)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12" *ngIf="!companies || companies.length === 0">
    <p-message severity="warning" text="No se encontraron empresas"></p-message>
  </div>
  }
</div>
