import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CompanyPageRoutingModule } from './company-routing.module';
import { CompanyPage } from './company.page';
import { TableModule } from 'primeng/table'; 
import { MessageModule } from 'primeng/message';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CompanyPageRoutingModule,
    MessageModule,
    TableModule,
    ButtonModule,
    ConfirmDialogModule,
    MessagesModule,
  ],
  providers: [ConfirmationService, MessageService],
  declarations: [CompanyPage]
})
export class CompanyPageModule {}
