import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompanyPage } from './company.page';

const routes: Routes = [
  {
    path: '',
    component: CompanyPage
  },
  {
    path: 'create-company',
    data: { breadcrumb: ' Create Company' },
    loadChildren: () => import('./create-company/create-company.module').then(m => m.CreateCompanyPageModule)
  },
  {
    path: 'edit/:id',
    data: { breadcrumb: ' Edit Company' },
    loadChildren: () => import('./edit-company/edit-company.module').then(m => m.EditCompanyPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyPageRoutingModule { }
