import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompaniesService } from './companies.service';
import { Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-companies',
  templateUrl: './company.page.html',
  styleUrls: ['./company.page.scss'],
})
export class CompanyPage implements OnInit {
  companyForm: FormGroup;
  companies: any[] = [];
  msgs: Message[] = [];
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private service: CompaniesService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.companyForm = this.fb.group({
      cif: ['', Validators.required],
      fiscal_postal_code: ['', Validators.required],
      fiscal_address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.getCompanies();
  }

  getCompanies() {
    this.service.getCompanies().subscribe(
      (data: any[]) => {
        this.companies = data['data'];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error('Error fetching companies:', error);
      }
    );
  }

  redirectToCreateCompany() {
    this.router.navigate(['/companies/create-company']);
  }

  onEditClick(company: any) {
    this.router.navigate(['/companies/edit', company.id], {
      state: { companyData: company },
    });
  }

  onDeleteClick(companyId: string) {
    this.confirmationService.confirm({
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar esta empresa?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.service
          .deleteCompany(companyId)
          .pipe(
            tap((response) => {
              this.getCompanies();
              this.showMessage('success', 'Empresa eliminada con éxito');
            }),
            catchError((error) => {
              this.showMessage('error', 'Error al eliminar la empresa');
              return of(null);
            })
          )
          .subscribe();
      },
    });
  }

  private showMessage(severity: string, detail: string) {
    this.msgs = [];
    this.msgs.push({ severity: severity, detail: detail });
  }
}
