import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Dashboard', icon: 'pi pi-home',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-home', routerLink: ['/dashboard'] }
                ]
            }
            // ,
            // {
            //     label: 'User management', icon: 'pi pi-user',
            //     items: [
            //         { label: 'Usuarios', icon: 'pi pi-user', routerLink: ['/users'] },
            //         { label: 'Empresas', icon: 'pi pi-chart-bar', routerLink: ['/companies'] }
            //     ]
            // }
        ];
    }
}
