<div class="p-grid">
  <div class="p-col-12">
    <h2>Listado de usuarios</h2>
  </div>

  <p-confirmDialog></p-confirmDialog>
  <p-messages [value]="msgs"></p-messages>

  @if(loading){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  } @else {
  <div class="p-col-12 mb-4 custom-align-right">
    <button
      pButton
      type="button"
      label="Crear Nuevo Usuario"
      icon="pi pi-plus"
      (click)="redirectToCreateUser()"
    ></button>
  </div>

  <div class="p-col-12" *ngIf="users && users.length > 0">
    <p-table [value]="users" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Creado</th>
          <th>Options</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>
            <button
              pButton
              type="button"
              class="mr-2"
              icon="pi pi-pencil"
              (click)="onEditClick(user)"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-briefcase"
              class="p-button-success"
              (click)="onAssignCompanyClick(user.id)"
            ></button>
            <button
              pButton
              type="button"
              style="margin-left: 7px !important"
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="onDeleteClick(user.id)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12" *ngIf="!users || users.length === 0">
    <p-message severity="warning" text="No se encontraron usuarios"></p-message>
  </div>
  }
</div>
