import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { CompaniesService } from '../company/companies.service';
import { Company } from '../models/company';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  companies: Company[] = [];
  user: User;
  loading: boolean = true;

  constructor(
    private userService: UserService,
    private router: Router,
    private companyService: CompaniesService
  ) {
    this.user = '';
  }

  ngOnInit() {
    this.getActualUser();
    this.getCompanies();
  }

  /**
   * Obtener datos del usuario actual en sesión
   */
  getActualUser() {
    this.userService.getActualUser().subscribe({
      next: (response) => {
        this.user = response;
      },
      error: (err) => {
        console.log('Error obteniendo datos del usuario actual: ', err);
      },
    });
  }

  /**
   * Ir a ESG con token de sesión del usuario actual
   */
  goTo(companyId) {
    const token = this.userService.getToken();
    const moduleUrl = environment.esrsURL + '#/dashboard';

    if (moduleUrl.startsWith('http://') || moduleUrl.startsWith('https://')) {
      // Si la URL comienza con 'http://' o 'https://', es una URL externa
      const tokenizedUrl =
        moduleUrl +
        (moduleUrl.includes('?') ? '&' : '?') +
        'token=' +
        token +
        '&' +
        'companyId=' +
        companyId;
      window.location.href = tokenizedUrl;
    } else {
      // Ruta interna
      this.router.navigateByUrl(moduleUrl);
    }
  }

  /**
   * Obtener listado de empresas registradas
   */
  getCompanies() {
    this.companyService.getCompanies().subscribe({
      next: (response) => {
        this.companies = response['data'];
        this.loading = false;
      },
      error: (err) => {
        console.error('Error obteniendo empresas: ', err);
        this.loading = false;
      },
    });
  }
}
