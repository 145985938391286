import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from './users.service';
import { Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit {
  users: any[] = [];
  msgs: Message[] = [];
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private service: UsersService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  onEditClick(user: any) {
    this.router.navigate(['/users/edit-users', user.id], {
      state: { userData: user },
    });
  }

  redirectToCreateUser() {
    this.router.navigate(['/users/create-users']);
  }

  /**
   * Cargar listado de usuarios registrados
   */
  getUsers() {
    this.service.getUsers().subscribe({
      next: (response: any) => {
        if (response.data) {
          this.users = response.data;
          this.loading = false;
        }
      },
      error: (err) => {
        console.error('Error al cargar listado de usuarios', err);
      },
    });
  }

  onDeleteClick(userId: string) {
    this.confirmationService.confirm({
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar esta empresa?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.service
          .deleteCompany(userId)
          .pipe(
            tap((response) => {
              this.getUsers();
              this.showMessage('success', 'Usuario eliminado con éxito');
            }),
            catchError((error) => {
              this.showMessage('error', 'Error al eliminar usuario');
              return of(null);
            })
          )
          .subscribe();
      },
    });
  }

  private showMessage(severity: string, detail: string) {
    this.msgs = [];
    this.msgs.push({ severity: severity, detail: detail });
  }

  onAssignCompanyClick(userId: string) {
    this.router.navigate(['/users/assign-users', userId]);
  }
}
