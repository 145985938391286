import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient, private userservice: UserService) { }

  getCompanies(): Observable<any[]> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.userservice.getToken(),
    });
    return this.http.get<any[]>(`${UserService.apiUrl}/api/companies`, { headers: headers });
  }

  deleteCompany(companyId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.userservice.getToken(),
    });

    const url = `${UserService.apiUrl}/api/companies/${companyId}`;
    return this.http.delete(url, { headers: headers });
  }
}
