import { Injectable } from '@angular/core';
import { Login } from '../models/login';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { Token } from '../models/token';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private headers: any;

  constructor(
    protected apiService: ApiService,
    private router: Router,
    private http: HttpClient
  ) {}

  /**
   * Acción de inicio de sesión con llamada al servidor
   * @param loginModel
   * @returns
   */
  public login(loginModel: Login): Observable<Token> {
    return this.http
      .post(`${environment.usersApiURL}/api/login`, loginModel)
      .pipe(
        tap((val: Token) => {
          this.setToken(val);
        })
      );
  }

  private timeout;
  /**
   * Asigno token a sessionStorage y tratamos tiempo de expiración del token
   * @param data
   */
  private setToken(data: any) {
    const token = data.token;
    const decoded: any = jwtDecode(token);

    // Obtener la fecha de expiración del token desde el payload decodificado
    const expires_at = new Date(decoded.exp * 1000).toISOString(); // Convertir de segundos a milisegundos y a ISO string
    const generated_at = new Date().toISOString(); // Obtener el tiempo actual en ISO string

    // Actualizar el objeto del token con las fechas
    const tokenData: Token = {
      token: data.token,
      generated_at: generated_at,
      expires_at: expires_at,
    };

    // Guardar el token y la información completa en sessionStorage
    sessionStorage.setItem('token', token);
    sessionStorage.setItem(
      'full-token',
      JSON.stringify({ user: data.user, token: tokenData })
    );

    // Calcular el tiempo restante hasta la expiración del token
    const expiresAtMillis = new Date(expires_at).getTime();
    const generatedAtMillis = new Date(generated_at).getTime();
    const compareTime = expiresAtMillis - generatedAtMillis;

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.logout();
    }, compareTime);
  }

  public getToken(): string {
    return sessionStorage.getItem('token') ?? '';
  }

  public getFullToken(): Token {
    return JSON.parse(sessionStorage.getItem('full-token') ?? '');
  }
  public isLogged(): boolean {
    if (!this.getToken()) return false;
    const token = this.getFullToken();
    return token ? true : false;
  }

  public isExpired(): boolean {
    if (!this.isLogged()) return true;
    const token = this.getFullToken();
    return new Date().getTime() > new Date(token.expires_at).getTime()
      ? true
      : false;
  }

  public isTimeToRefresh(): boolean {
    if (this.isLogged()) {
      const token = this.getFullToken();
      const expires_at = new Date(token.expires_at).getTime();
      const generated_at = new Date(token.generated_at).getTime();
      const time = new Date().getTime();
      const restTime = expires_at - time;
      const compareTime = expires_at - generated_at;

      return restTime <= 0.8 * compareTime;
    }

    return false;
  }

  public logout(): void {
    if (this.isLogged()) {
      this.postLogout();
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  postLogout(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
    });
    return this.http.post(`${environment.usersApiURL}/api/logout`, null, {
      headers: headers,
    });
  }

  public refresh(): Observable<Token> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getToken()}`,
      }),
    };

    return this.http
      .post<Token>(`${environment.usersApiURL}/api/refreshToken`, null, headers)
      .pipe(
        tap((val: Token) => {
          this.setToken(val);
        })
      );
  }
}
