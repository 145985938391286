import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private userService: UserService) { }

  getUsers(): Observable<any[]> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.userService.getToken(),
    });
    return this.http.get<any[]>(`${UserService.apiUrl}/api/users`, { headers: headers });
  }

  deleteCompany(companyId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.userService.getToken(),
    });

    const url = `${UserService.apiUrl}/api/users/${companyId}`;
    return this.http.delete(url, { headers: headers });
  }
}
